













import { InputSetups } from "@/mixins/input-setups";
import ChipValueSettingView from "@/components/ChipValueSettingView.vue";

import TypedChipsSettingMixin from "piramis-base-components/src/components/TypedChips/TypedChipsSettingMixin";

import { Component, Mixins, Ref } from 'vue-property-decorator';

@Component({
  components: {
    ChipValueSettingView
  }
})
export default class ReferralFilterModuleItems extends Mixins(InputSetups, TypedChipsSettingMixin) {
  @Ref('chipValue') chipValue!: ChipValueSettingView

  mounted() {
    this.processChipsValues = this.chipValue.processChipsValues
  }
}
