import ReferralFilterModuleItems
  from "@/components/Modules/components/typedChipsWrappers/ReferralFilterModuleItems.vue";
import ChipValueSettingView from "@/components/ChipValueSettingView.vue";
import i18n from "@/i18n/i18n";

import { PossibleChipTypeInfo } from 'piramis-base-components/src/components/TypedChips/typedChips.types'

export const BASE_SUPER_LIST_TYPES: Array<PossibleChipTypeInfo> = [
    {
      type: 'Equals',
      isDefault: true,
      label: i18n.t('base_super_list_type_equals').toString(),
      prefix: 'e:',
      color: 'blue',
      view: ChipValueSettingView
    },
    {
      type: 'Contains',
      label: i18n.t('base_super_list_type_contains').toString(),
      prefix: 'c:',
      color: 'orange',
      view: ChipValueSettingView
    },
    {
      type: 'StartWith',
      label: i18n.t('base_super_list_type_start_with').toString(),
      prefix: 'sw:',
      color: 'red',
      view: ChipValueSettingView
    },
    {
      type: 'EndWiths',
      label: i18n.t('base_super_list_type_end_with').toString(),
      prefix: 'ew:',
      color: 'green',
      view: ChipValueSettingView
    },
    {
      type: 'RegExp',
      label: i18n.t('base_super_list_type_reg_exp').toString(),
      prefix: 'rx:',
      color: 'cyan',
      view: ChipValueSettingView
    },
]

export const BOT_COMMAND_FILTER_TYPES: Array<PossibleChipTypeInfo> = BASE_SUPER_LIST_TYPES.filter(t => [ 'Contains', 'Equals', 'StartWith' ].includes(t.type))
export const REPUTATION_TYPES: Array<PossibleChipTypeInfo> = BASE_SUPER_LIST_TYPES.filter(t => [ 'Contains', 'Equals' ].includes(t.type)).concat([ {
  type: 'Fill',
  label: i18n.t('reputation_message_super_list_type_fill').toString(),
  prefix: 'f:',
  color: 'cyan',
  view: ChipValueSettingView
} ])

export const STICKER_PACK_FILTER_TYPES: Array<PossibleChipTypeInfo> = [
    {
  type: 'StickerPack',
  isDefault: true,
  label: i18n.t('sticker_pack_super_list_type_sticker_pack').toString(),
  prefix: 'sp:',
  color: 'blue',
  view: ChipValueSettingView
},
  {
    type: 'CustomEmojiPack',
    label: i18n.t('sticker_pack_super_list_type_custom_emoji_pack').toString(),
    prefix: 'ce:',
    color: 'orange',
    view: ChipValueSettingView
  }, ]

export const REFERRAL_FILTER_TYPES: Array<PossibleChipTypeInfo> = [
  {
    type: 'Link',
    isDefault: true,
    label: i18n.t('referral_super_list_type_link').toString(),
    prefix: 'l:',
    color: 'blue',
    view: ReferralFilterModuleItems
  },
  {
    type: 'Source',
    label: i18n.t('referral_super_list_type_source').toString(),
    prefix: 's:',
    color: 'orange',
    view: ReferralFilterModuleItems
  },
  {
    type: 'CodeEquals',
    label: i18n.t('referral_super_list_type_code_equals').toString(),
    prefix: 'ce:',
    color: 'red',
    view: ReferralFilterModuleItems
  },
  {
    type: 'CodeStartWith',
    label: i18n.t('referral_super_list_type_code_start_with').toString(),
    prefix: 'csw:',
    color: 'green',
    view: ReferralFilterModuleItems
  },
  {
    type: 'CodeContains',
    label: i18n.t('referral_super_list_type_code_contains').toString(),
    prefix: 'c:',
    color: 'cyan',
    view: ReferralFilterModuleItems
  },
]
